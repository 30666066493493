<template>
    <form>
        <button 
            type="button"
            class="btn btn-light btn-block mb-3"
            @click="switchConversion">
            {{ conversion }}
        </button>

        <div v-if="showDecodingWarning" class="alert alert-warning" role="alert">
            The string to be decoded is not correctly encoded.
        </div>

        <div 
            class="form-group pt-2">
            <label 
                class="font-weight-bold"
                for="inputText">
                input
            </label>
            <textarea 
                class="form-control"
                id="inputText"
                rows="3"
                @input="convertText"
                v-model="inputText">
            </textarea>
        </div>

        <div 
            class="form-group pt-2">
            <label 
                class="font-weight-bold"
                for="outputText">
                output {{ showDecodingWarning ? '(invalid, cannot decode base64 string)' : '' }}
            </label>
            <textarea 
                class="form-control"
                id="outputText"
                rows="3"
                v-model="outputText">
            </textarea>
        </div>
    </form>
</template>

<script>
    export default {
        name: 'Converter',
        data: function() {
            return {
                conversion: 'encode',
                inputText: '',
                outputText: '',
                showDecodingWarning: false
            };
        },
        methods: {
            convertText: function() {
                if (this.conversion === 'encode') {
                    this.outputText = window.btoa(this.inputText);
                    this.showDecodingWarning = false;
                } else {
                    try {
                        this.outputText = window.atob(this.inputText);
                        this.showDecodingWarning = !this.isBase64(this.outputText);
                    } catch(e) {
                        this.showDecodingWarning = true;
                    }
                }
            },
            isBase64: function(str) {
                var base64Matcher = new RegExp("^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$");

                if (base64Matcher.test(str)) {
                    return true;
                } else {
                    return false;
                }
            },
            switchConversion: function() {
                this.conversion = this.conversion === 'encode' ? 'decode' : 'encode';
                this.convertText();
            }
        }
    }
</script>